import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    footerAppButtonText: {
        ...uikit.subtitle,
        'line-height': '1',
        'font-weight': 500,
        'white-space': 'nowrap',
        color: variable.darkColor
    },
    breadcrumb: media(width, height, 'w', {
        1726: {
            width: '1704px',
            display: 'flex',
            'align-items': 'center',
            padding: '14px 0 5px'
            // 'padding-left': '20px'

        },
        1500: {
            width: '1504px',
            display: 'flex',
            'align-items': 'center',
            padding: '14px 0 5px'
            // 'padding-left': '20px'

        },
        768: {
            width: '100%',
            display: 'flex',
            'align-items': 'center',
            'padding': '8px 16px'
            // 'padding-left': '40px'

        },
        0: {
            width: '100%',
            display: 'flex',
            'align-items': 'center',
            'padding': '0 12px',
            'padding-left': '20px'

        }
    }),
    breadItem: {
        display: 'flex',
        'align-items': 'center',
        'margin-right': '10px',
        color: variable.blueColor,
        ...uikit.small,
        cursor: 'pointer'
    },
    breadItemActive: { color: variable.greySuperDarkColor },
    breadItemTitle: media(width, height, 'w', {
        768: {
            'margin-right': '10px',
            cursor: 'pointer'
        },
        0: { 'margin-left': '10px' }
    }),
    singleCompanyLogoStyles: media(width, height, 'w', {
        993: {
            width: '192px',
            height: '44px',
            fill: '#2E6D8E',
            textFill: variable.blackColor
        },
        0: {
            width: '192px',
            height: '44px',
            fill: '#2E6D8E',
            textFill: variable.blackColor
        }
    }),
    svgSingleWrapper: media(width, height, 'w', {
        993: { height: '44px' },
        0: { height: '44px' }
    }),
    mainWrapper: media(width, height, 'w', {
        1536: {
            'flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column',
            'margin-top': 'calc(70px + 24px)',
            'max-width': '1320px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            width: '100%'
        },
        992: {
            'flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column',
            'margin-top': 'calc(66px + 24px)'
        },
        768: {
            'flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column',
            'margin-top': 'calc(82px + 24px)'
        },
        0: {
            'flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column',
            'margin-top': 'calc(60px + 16px)'
        }
    }),
    mainWrapperWeb: {
        background: `${variable.mainWrapperWebBackground}, url(../../../../src/img/zendo_2_bg.jpg)`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center'
        // 'transition': 'background 2s'
    },
    mainWrapperWebLogin: { background: `${variable.mainWrapperWebBackground}, url(../../../../src/img/zendo_2_bg.jpg)` },
    mainWrapperWebRegister: { background: `${variable.mainWrapperWebRegisterBackground}, url(../../../../src/img/zendo_2_bg.jpg)` },
    mainWrapperWebRestore: { background: `${variable.mainWrapperWebRestoreBackground}, url(../../../../src/img/zendo_2_bg.jpg)` },
    mainContainerWeb: media(width, height, 'w', {
        768: {
            'max-width': variable.containerWidth,
            margin: '0 auto',
            width: '100%',
            'min-height': '100%',
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': '1'
        },
        0: {
            'max-width': variable.containerWidth,
            margin: '0 auto',
            width: '100%',
            padding: '0',
            height: '100%',
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    blockXl: media(width, height, 'w', {
        1726: {
            'max-width': '1704px',
            'margin-left': 'auto',
            'margin-right': 'auto'
        },
        1500: {
            'max-width': '1504px',
            'margin-left': 'auto',
            'margin-right': 'auto'
        },
        0: {
            'max-width': '100%',
            'margin-left': 'auto',
            'margin-right': 'auto'
        }
    }),
    flexColumn: { 'flex-direction': 'column' },
    headerShadow: { 'box-shadow': `0px 5px 15px ${variable.blackExtraTransparentColor}` },
    mainHeader: media(width, height, 'w', {
        1536: {
            display: 'flex',
            padding: '10px 0'
        },
        0: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-end',
            padding: '8px 16px'
        }
    }),
    mainMenuWrapper: {
        width: '100%',
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'flex-end'
    },
    menuActionTop: {
        padding: '0 8px 12px',
        display: 'flex'
    },
    menuActionItem: {
        'flex-grow': '1',
        'min-width': '50%',
        padding: '0 4px 4px',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'stretch'
    },
    menuActionBtn: {
        width: '100%',
        margin: '0'
    },
    mainMenuHamburgerNativeActive: media(width, height, 'w', {
        993: { display: 'none' },
        768: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            flex: '0 0 24px',
            height: '66px',
            left: '16px',
            margin: '0'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            flex: '0 0 24px',
            height: '44px',
            left: '16px',
            margin: '0'
        }
    }),
    mainMenuHamburgerNativeDisactive: { display: 'none' },
    mainMenuLogo: media(width, height, 'w', {
        993: {
            display: 'flex',
            'justify-content': 'flex-start',
            order: '-1',
            'align-items': 'center'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'margin': '0 auto',
            'padding-right': '26px'
        }
    }),
    mainMenuLogoImg: media(width, height, 'w', {
        1320: {},
        992: { 'max-width': '100px' },
        0: { 'max-height': '24px' }
    }),
    mainMenuLogoLink: media(width, height, 'w', {
        992: {
            height: '30px',
            display: 'flex',
            'align-items': 'center'
        },
        0: {
            height: '24px',
            display: 'flex',
            'align-items': 'center'
        }
    }),
    mainMenuLogoNative: {
        993: { display: 'none' },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center'
            // 'order': '1'
        }
    },
    mainMenuHamburgerNativeImg: {
        width: '20px',
        height: '20px'
    },
    mainContent: {
        display: 'flex',
        'flex-grow': 1
    },
    wrapAuthBlockWeb: {
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        flex: '1'

    },
    authBlockWeb: media(width, height, 'w', {
        768: {
            background: variable.whiteColor,
            'box-shadow': `0px 30px 60px ${variable.greyShadowColor}`,
            'border-radius': '24px',
            'max-width': '480px',
            width: '100%',
            display: 'flex',
            'flex-direction': 'column',
            padding: '48px 40px',
            margin: '0 auto 0',
            position: 'relative'
        },
        0: {
            position: 'absolute',
            top: '-100%'
        }
    }),
    authBlockWebActive: {
        background: variable.whiteColor,
        'box-shadow': `0px 30px 60px ${variable.greyShadowColor}`,
        'border-radius': '24px',
        width: '100%',
        'flex-direction': 'column',
        'justify-content': 'space-between',
        'flex-grow': '1',
        display: 'flex',
        padding: '32px 16px 16px 16px',
        margin: '0 auto 0'
    },
    authBlockNativeActive: {
        background: variable.whiteColor,
        'box-shadow': `0px 30px 60px ${variable.greyShadowColor}`,
        'border-radius': '24px',
        width: '100%',
        'flex-direction': 'column',
        'justify-content': 'space-between',
        'flex-grow': '1',
        display: 'flex',
        padding: '32px 16px 16px 16px',
        margin: '0 auto 0'
    },
    authBlockNativeNotActive: { display: 'none' },
    authHeading: {
        display: 'flex',
        'flex-direction': 'row',
        'margin-bottom': '24px'
    },
    authHeadingTitle: {
        'font-weight': '500',
        ...uikit.h5,
        color: variable.blueDarkColor,
        'text-align': 'center',
        background: 'transparent',
        flex: 1,
        'margin-bottom': '16px'
    },
    authHeadingText: {
        ...uikit.text,
        color: variable.blueDarkColor,
        'text-align': 'center',
        background: 'transparent'
    },
    wrapButtonMenu: {
        padding: '16px',
        'border-color': variable.greyColor,
        'border-width': '1px',
        'border-style': 'solid',
        'border-left-width': '0',
        'border-right-width': '0',
        'margin-bottom': '24px'
    },
    button: {
        'border-radius': '14px',
        height: '48px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        margin: '8px 0'
    },
    buttonGray: { 'background-color': variable.greyTransparentColor },
    buttonOrange: { 'background-color': variable.orangeColor },
    buttonOrangeHover: { 'background-color': variable.orangeLightColor },
    buttonGrayHover: {
        'background-color': variable.greyColor,
        cursor: 'pointer',
        'user-select': 'none'
    },
    buttonGrayFocus: { 'background-color': variable.greyLightColor },
    buttonOrangeText: {
        'font-weight': '500',
        ...uikit.text,
        color: variable.whiteColor
    },
    wrapLinksMenu: {
        'flex-grow': 1,
        padding: '0',
        background: variable.whiteColor
    },
    mainMenu: media(width, height, 'w', {
        993: {
            display: 'flex',
            // margin: '0 -5px',
            'flex-grow': '1',
            // 'justify-content': 'space-between',
            'flex-direction': 'row',
            position: 'relative',
            'justify-content': 'space-between',
            'margin-bottom': '16px',
            'margin-top': '24px',
            'align-items': 'center'
        },
        0: {
            display: 'flex',
            // margin: '0 -5px',
            'flex-grow': '1',
            // 'justify-content': 'space-between',
            'flex-direction': 'row',
            position: 'relative',
            'justify-content': 'center',
            'margin-bottom': '16px',
            'margin-top': '24px',
            'align-items': 'center'
        }
    }),
    mainMenuItem: media(width, height, 'w', {
        1300: {
            ...uikit.text,
            padding: '0',
            color: variable.darkColor,
            fill: variable.darkColor,
            transition: 'color 0.5s',
            'padding-right': '40px'
        },
        1100: {
            ...uikit.text,
            padding: '0',
            color: variable.darkColor,
            fill: variable.darkColor,
            transition: 'color 0.5s',
            'padding-left': '12px',
            'padding-right': '12px'
        },
        993: {
            ...uikit.text,
            padding: '0',
            color: variable.darkColor,
            fill: variable.darkColor,
            transition: 'color 0.5s',
            'padding-left': '6px',
            'padding-right': '6px'
        },
        0: {
            ...uikit.text,
            'line-height': '1.25',
            'font-weight': '400',
            'min-height': '24px',
            padding: '20px',
            color: variable.darkColor,
            fill: variable.darkColor,
            background: variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'align-items': 'center',
            'padding-left': '20px',
            'padding-right': '20px',
            width: '100%'
        }
    }),
    menuItemImg: {
        width: '24px',
        height: '24px',
        'margin-bottom': '0',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'margin-right': '20px'
    },
    itemMenuStyle: media(width, height, 'w', {
        768: {
            color: variable.darkColor,
            'font-size': '16px'
        },
        0: {
            display: 'block',
            color: variable.darkColor,
            'font-size': '14px'
        }
    }),
    officeMenuItemTextHover: { color: variable.blueColor },
    itemMenuStyleHover: { opacity: '.85' },
    officeSubMenuItemMob: { color: variable?.blueTotalGray },
    menuItemTextP: media(width, height, 'w', {
        993: {
            'padding-left': '0',
            color: variable.darkHalfTransparentColor,
            'font-weight': '500',
            ...uikit.small,
            position: 'relative',
            'flex-grow': 1
        },
        0: {
            'padding-left': '23px',
            color: variable.darkColor
        }
    }),
    menuItemTextHover: { color: variable.blueColor },
    menuFooter: media(width, height, 'w', {
        993: { display: 'none' },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'flex-end',
            'align-items': 'center',
            'background-color': variable.whiteColor,
            padding: '16px 12px 8px',
            'text-align': 'center',
            'font-size': '0.625rem',
            'line-height': '1.2',
            color: variable.darkColor
        }
    }),
    menuFooterText: { 'margin-bottom': '4px' },
    menuFooterDecorElement: {
        display: 'inline-block',
        width: '134px',
        height: '5px',
        'margin-top': '17px'
    },
    //    App btn
    buttonAppSvg: media(width, height, 'w', {
        993: {
            width: '28px',
            height: '31px'
        },
        0: {
            'width': '24px',
            'height': '29px'
        }
    }),
    btnApp: media(width, height, 'w', {
        993: { display: 'none' },
        0: {
            padding: '10px 16px',
            'border-radius': '14px',
            display: 'flex',
            'background-color': 'transparent',
            outline: 'transparent',
            position: 'relative',
            'flex-direction': 'row',
            'justify-content': 'center',
            'font-weight': '500',
            flex: 1,
            color: variable.darkColor,
            'max-height': '56px',
            'max-width': '170px'
        }
    }),
    btnAppNative: {
        padding: '8px 8px 8px 10px',
        'border-radius': '10px',
        display: 'flex',
        'align-items': 'center',
        'background-color': 'transparent',
        position: 'relative',
        flex: 1,
        'flex-direction': 'row'
    },
    appWrapperButtons: media(width, height, 'w', {
        993: { display: 'none' },
        0: {
            display: 'flex',
            padding: '16px 12px 0',
            'align-items': 'center',
            'justify-content': 'center',
            'background-color': variable.whiteColor
        }
    }),
    appButtonText: {
        color: 'inherit',
        ...uikit.small,
        'line-height': '1.28'
    },
    appButtonTextNative: {
        color: variable.blackColor,
        'font-size': 14
    },
    appButtonTextSm: {
        color: 'inherit',
        'font-size': '0.625rem',
        'line-height': '1.6'
    },
    appButtonTextSmNative: {
        color: variable.blackColor,
        'font-size': 10
    },

    //    END App btn
    mainMenuItemHover: {
        cursor: 'pointer',
        color: variable.blueColor,
        fill: variable.blueColor
    },
    imgBackground: {
        width: '100%',
        flex: 1,
        display: 'flex',
        'flex-direction': 'column',
        position: 'relative'
    },
    webMenuBlock: media(width, height, 'w', {
        993: {
            display: 'flex',
            'align-items': 'stretch'
        },
        0: { display: 'none' }
    }),
    footerBlockWeb: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'margin-top': '32px',
            'margin-bottom': '32px'
        },
        0: { display: 'none' }
    }),
    footerBlockNative: { display: 'none' },
    footerText: {
        color: variable.whiteColor,
        ...uikit.small
    },
    NativeMenuBlock: {
        position: 'absolute',
        top: '0',
        left: '-100%',
        width: '100%',
        height: '100%',
        display: 'flex',
        color: variable.whiteColor,
        'z-index': '10',
        'background-color': variable.blackTransparentColor
    },
    NativeMenu: {
        display: 'flex',
        'flex-direction': 'column',
        width: '250px',
        'border-radius': '5px',
        padding: ' 15px',
        left: '0',
        top: '0',
        'background-color': variable.whiteColor,
        height: '100%'
    },
    WebMenu: media(width, height, 'w', {
        993: { display: 'none' },
        425: {
            display: 'flex',
            'flex-direction': 'column',
            left: '0',
            top: '0',
            'background': 'rgba(164, 179, 202, 1)',
            // 'background': variable.mainWrapperMenuBackground,
            height: '100vh',
            'box-shadow': `0px 5px 20px ${variable.blackExtraTransparentColor}`,
            'border-radius': '0',
            width: '375px'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            left: '0',
            top: '0',
            'background': 'rgba(164, 179, 202, 1)',
            // 'background': variable.mainWrapperMenuBackground,
            height: window ? `${window?.innerHeight}px` : '100vh',
            'box-shadow': `0px 5px 20px ${variable.blackExtraTransparentColor}`,
            'border-radius': '0',
            width: '100%'
        }
    }),
    widgetUser: {
        display: 'flex',
        'align-items': 'center',
        cursor: 'pointer',
        position: 'relative',
        'z-index': '1'
    },
    singleImage: {
        width: '38px',
        height: '38px',
        border: `2px solid ${variable.greyColor}`,
        'border-radius': '50%',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        margin: '0 12px'
    },
    singleImageItem: {
        'border-radius': '50%',
        width: '34px',
        height: '34px'
    },
    AuthMenu: {
        padding: '24px 0',
        'border-radius': '0',
        'border-top-right-radius': '20px',
        width: '278px'
    },
    formLinkText: {
        color: variable.whiteColor,
        ...uikit.small,
        'font-weight': '500'
    },
    marginLeft24: { ...uikit.ml4 },
    dFlex: { display: 'flex' },
    buttonYellow: {
        'background-color': variable.orangeColor,
        transition: 'all ease .25s',
        color: variable.whiteColor
    },
    buttonYellowHover: {
        'background-color': variable.orangeDarkColor,
        color: variable.whiteColor,
        transition: 'all ease .25s'
    },
    buttonTransparentOutline: {
        background: 'transparent',
        color: variable.whiteColor,
        border: `2px solid ${variable.whiteLessTransparentColor}`,
        transition: 'all ease .25s'
    },
    buttonTransparentOutlineHover: {
        background: variable.whiteColor,
        color: variable.blueColor,
        border: `2px solid ${variable.whiteColor}`,
        transition: 'all ease .25s'
    },
    buttonWhite: {
        background: variable.whiteColor,
        color: variable.blueColor,
        transition: 'all ease .25s'
    },
    buttonWhiteHover: {
        background: variable.whiteColor,
        color: variable.blueColor,
        transition: 'all ease .25s'
    },
    justifyContentBetween: { 'justify-content': 'space-between' },
    enterButton: media(width, height, 'w', {
        1536: {
            'font-weight': '500',
            'min-width': '113px',
            'min-height': '60px',
            ...uikit.text,
            display: 'inline-flex',
            'align-items': 'center',
            'flex-direction': 'column',
            'justify-content': 'center',
            cursor: 'pointer',
            'border-radius': '14px',
            'margin-right': '24px'
        },
        0: {
            'font-weight': '500',
            'min-width': '113px',
            'min-height': '60px',
            ...uikit.text,
            display: 'inline-flex',
            'align-items': 'center',
            'flex-direction': 'column',
            'justify-content': 'center',
            cursor: 'pointer',
            'border-radius': '14px',
            'margin-right': '18px'
        }
    }),
    menuCloseBtn: {
        width: '44px',
        height: '44px',
        'flex-shrink': '0',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        background: variable.whiteLessTransparentColor,
        'border-radius': '12px'
    },
    menuCloseBtnIcon: { fill: variable.whiteColor },
    menuTitle: media(width, height, 'w', {
        993: { display: ' none' },
        0: {
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'margin-top': '0',
            'align-items': 'center',
            'padding': '10px 12px 16px'
        }
    }),
    menuTitleLogo: {
        'margin-right': 'auto',
        'margin-left': 'auto',
        transform: 'translateX(-22px)',
        width: 'auto',
        height: '44px'
    },
    menuScroll: media(width, height, 'w', {
        993: {
            display: 'block',
            'overflow-y': 'visible'
        },
        0: {
            'overflow-y': 'auto',
            'flex-grow': '1',
            'flex-shrink': '1',
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    menuWrapper: media(width, height, 'w', {
        993: {
            display: 'block',
            position: 'relative',
            'border-radius': '0',
            overflow: 'visible'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            position: 'relative',
            'border-radius': '20px 20px 0 0',
            overflow: 'hidden',
            'flex-grow': '1',
            'flex-shrink': '0'
        }
    }),
    menuLangSwitcher: media(width, height, 'w', {
        0: {
            'min-height': '65px',
            'border-bottom': `1px solid ${variable.darkSuperTransparentColor}`,
            padding: '16px 23px',
            color: variable.darkColor,
            background: variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'align-items': 'center'
        }
    }),
    menuLangSwitcherLangFlag: {
        'flex-grow': '0',
        'flex-shrink': '0',
        width: '24px',
        height: '24px',
        'border-radius': '50%',
        background: variable.greyColor,
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        overflow: 'hidden'
    },
    menuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    menuLangSwitcherInfo: { padding: '0 0 0 20px' },
    menuLangSwitcherTitle: {
        ...uikit.tiny,
        'line-height': '1.16',
        color: variable.greySuperDarkColor,
        margin: '0 0 2px'
    },
    menuLangSwitcherName: {
        ...uikit.small,
        'line-height': '1.14'
    },
    langSwitcher: media(width, height, 'w', {
        992: { width: '74px' },
        0: { width: '44px' }
    }),
    headerSticky: media(width, height, 'w', {
        768: {
            position: 'fixed',
            top: '0px',
            'z-index': 999
        },
        0: {
            position: 'fixed',
            top: '0px',
            'z-index': 9
        }
    }),
    bgcWhite: { 'background-color': variable.whiteColor },
    fullWidth: { width: '100%' },

    footer: media(width, height, 'w', {
        1536: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '16px 0',
            'max-width': '1320px'
        },
        992: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '16px 30px'
        },
        576: {
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'space-between',
            padding: '34px 16px 48px',
            'text-align': 'center'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'flex-direction': 'column',
            padding: '34px 16px 48px',
            'max-width': '1504px'
        }
    }),
    copyright: media(width, height, 'w', {
        992: {
            'user-select': 'none',
            'font-weight': 'normal',
            ...uikit.small,
            color: '#606E84',
            'font-weight': 500
        },
        0: {
            ...uikit.small,
            color: '#606E84',
            'font-weight': 500,
            'margin-bottom': '16px'
        }
    }),
    dev: media(width, height, 'w', {
        992: {
            display: 'flex',
            'flex-direction': 'row',
            'align-items': 'center'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    devText: media(width, height, 'w', {
        768: {
            'user-select': 'none',
            ...uikit.small,
            color: variable.calcColor('black', .6),
            'margin-right': '4px'
        },
        0: {
            ...uikit.small,
            color: variable.calcColor('black', .6),
            'margin-right': '4px'
        }
    }),
    devLink: {
        ...uikit.small,
        color: variable.blueColor,
        'font-weight': '500',
        'text-decoration': 'underline'
    },
    devLinkHover: { 'text-decoration': 'none' },
    cursorPointer: { ...uikit.pointer },
    centered: {
        'margin-left': 'auto',
        'margin-right': 'auto'
    },
    // Lang Modal
    modalContent: media(width, height, 'w', {
        0: {
            width: '100%',
            height: '100%',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'flex-end'
        }
    }),
    modalBody: media(width, height, 'w', {
        425: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 8px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        },
        0: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 8px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    modalTopDecor: {
        width: '32px',
        height: '4px',
        background: variable.greyExtraLightColor,
        'border-radius': '100px',
        margin: '0 0 20px'
    },
    modalBottomDecor: {
        width: '134px',
        height: '4px',
        margin: '21px 0 0'
    },
    modalTitle: {
        'font-weight': 500,
        ...uikit.subtitle
    },
    modalLangListWrapper: {
        width: '100%',
        overflow: 'hidden'
    },
    modalLangList: { margin: '-3px 0 -1px' },
    modalLangListItem: {
        'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
        padding: '16px 0',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
    },


    //sas
    langSwitcherRight: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center'
        },
        0: {
            display: 'none',
            'align-items': 'center'
        }
    }),
    menuLangSwitcherDesktop: {
        margin: '0 30px',
        width: '40px',
        'min-width': '40px',
        height: '40px',
        border: `1px solid ${variable.greyExtraDarkColorTransparent}`,
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '12px',
        'cursor': 'pointer'
    },
    menuLangSwitcherFlag: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
    },
    menuLangSwitcherLangFlagImgDesktop: {
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuLangSwitcherNameDesktop: {
        'text-transform': 'capitalize',
        ...uikit.small
    },
    menuLangsWrapper: {
        position: 'relative',
        'z-index': '1'
    },
    menuLangsBackdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'transparent',
        'z-index': '-2'

    },
    modalLangListDesktop: {
        'overflow': 'hidden',
        'border-radius': '14px'
    },
    modalLangListArrow: {
        position: 'absolute',
        top: '-12px',
        height: '14px',
        left: '50%',
        width: '33px',
        transform: 'translateX(-50%)',
        'z-index': '-1',
        fill: variable.whiteColor
    },
    modalLangListWrap: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        'background': variable.whiteColor,
        'border-radius': '14px',
        'margin-top': '14px',
        'box-shadow': `0 0 10px ${variable.greyExtraDarkMHalfTransparentColor}`
    },
    modalUserListWrap: { left: '32px' },
    modalLangListItemDesktop: {
        display: 'flex',
        'align-items': 'center',
        padding: '12px',
        'border-top': `1px solid ${variable.darkTransparentColor}`,
        'cursor': 'pointer',
        fill: variable.blueTotalGray
    },
    modalLangListItemFirst: { 'border-top': 'none' },
    modalLangListItemHover: { 'background': `${variable.greyExtraDarkTransparentColor}` },
    menuLangSwitcherListFlag: {
        'margin-right': '10px',
        background: variable.greyExtraLightColor,
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuIconUser: {
        'margin-right': '10px',
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px',
        fill: 'inherit'
    },
    menuIconUserHover: {
        'background': variable.greyExtraDarkTransparentColor,
        fill: variable.blueColor,
        color: variable.blueColor
    },
    menuLangSwitcherInfoDesktop: media(width, height, 'w', {
        768: {
            ...uikit.small,
            'white-space': 'pre'
        },
        0: {
            ...uikit.text,
            'line-height': '1.142',
            'white-space': 'pre'
        }
    }),
    webMenuBlockButtons: {
        'margin-left': '20px',
        display: 'flex',
        'align-items': 'center'
    },
    mainMenuButtonHover: { opacity: '0.6' },
    mainMenuButton: {
        'cursor': 'pointer',
        'font-weight': '500',
        padding: '0 12px',
        color: variable.darkColor,
        'border-left': `1px solid ${variable.darkExtraTransparentColor}`
    },
    mainMenuButtonFirst: { 'border-left': 'none' },
    authWrapper: {
        'background-color': variable.whiteColor,
        padding: '24px 20px 0px 8px',
        border: `1px solid ${variable.darkSuperTransparentColor}`
    },
    authWrapperHeader: {
        display: 'flex',
        'align-items': 'center'
    },
    authWrapperBody: {
        'padding-left': '66px',
        color: variable.blueTotalGray
    },
    authWrapperBodyItem: { 'margin-bottom': '12px' },
    subMenuWrapper: media(width, height, 'w', {
        993: {
            position: 'absolute',
            'z-index': '2',
            'width': '204px',
            background: variable.whiteColor,
            padding: '8px',
            'border-radius': '14px',
            // filter: `drop-shadow(0px 10px 100px ${variable.blackTransparentColor})`,
            'box-shadow': `0px 0px 15px ${variable?.blackExtraTransparentColor}`,
            left: '50%',
            top: 'calc(100%)',
            transform: 'translateX(-50%)'
        },
        0: {

            'width': '100%',
            background: variable.whiteColor,
            'padding-top': '12px'
        }
    }),
    singleSubMenuItem: media(width, height, 'w', {
        993: {
            'border-radius': '8px',
            padding: '8px'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            'border-radius': '8px',
            'padding-left': '64px',
            'padding-bottom': '12px'
        }
    }),
    officeMenuItemText: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between',
        'flex-grow': '1'
    },
    officeMenuItemTextP: media(width, height, 'w', {
        992: {
            'font-size': '1rem',
            'line-height': '1.18'
        },
        0: {
            width: '100%',
            'font-size': '1rem',
            'line-height': '1.28'
        }
    }),
    adminSubMenuActive: { background: variable.greyExtraDarkTransparentColor },
    singleSubMenuItemText: {
        ...uikit.text,
        color: variable.darkColor,
        cursor: 'pointer'
    },
    singleSubMenuActiveText: { color: variable.blueColor },
    wrapperItem: media(width, height, 'w', {
        993: {
            position: 'relative',
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            ...uikit.justifyContentCenter,
            cursor: 'pointer'
        },
        0: {
            'border-bottom': `1px solid ${variable.darkSuperTransparentColor}`,
            position: 'relative',
            ...uikit.flexRow,
            ...uikit.alignItemsCenter,
            ...uikit.justifyContentCenter,
            cursor: 'pointer'
        }
    }),
    footerWrap: {
        'margin-top': 'auto',
        'z-index': '1'
    },
    footerDown: media(width, height, 'w', {
        1700: {
            'border-top': `1px solid ${variable.greyColor2}`,
            background: variable.whiteColor
        },
        1024: {
            'border-top': `1px solid ${variable.greyColor2}`,
            background: variable.whiteColor
        },
        0: {
            'border-top': `1px solid ${variable.greyColor2}`,
            background: variable.whiteColor
        }
    }),
    footerLinkMobile: media(width, height, 'w', {
        1700: {
            'background-color': variable.blueColor,
            height: '80px',
            'margin-top': '96px'
        },
        1024: {
            'background-color': variable.blueColor,
            height: '80px',
            'margin-top': '96px',
            'padding': '0 8px 0 16px'
        },
        768: {
            'background-color': variable.blueColor,
            height: '140px',
            'margin-top': '36px',
            'padding': '0 8px 0 16px'
        },
        0: {
            width: '100%',
            'background-color': variable.blueColor,
            height: 'auto',
            'border-radius': '20px 20px 0px 0px'
            // 'margin-top': '100px',
            // 'padding': '0 32px'
        }
    }),
    footerLinkMobileWrapper: media(width, height, 'w', {
        1700: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            height: '100%'
        },
        768: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            height: '100%'
        },
        0: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            height: '100%',
            'flex-direction': 'column'
        }
    }),
    footerLinkMobileImage: media(width, height, 'w', {
        992: {
            width: '320px',
            position: 'relative',
            height: '100%',
            order: 1
        },
        768: {
            width: '330px',
            position: 'relative',
            height: '100%',
            order: 1
        },
        0: {
            width: '320px',
            position: 'relative',
            height: '190px',
            order: 3
        }
    }),
    footerImage: {
        position: 'absolute',
        display: 'flex',
        'align-items': 'flex-end',
        width: '49%',
        bottom: 0,
        'height': '175px'
    },
    footerImageItem: { width: '100%' },
    footerLinkMobileText: media(width, height, 'w', {
        768: {
            color: variable.whiteColor,
            'font-weight': 500,
            'font-size': '1.375rem',
            order: 2,
            'padding-left': '1rem'
        },
        0: {
            color: variable.whiteColor,
            'font-weight': 500,
            'font-size': '1.375rem',
            'margin-top': '24px',
            'margin-bottom': '16px',
            order: 1,
            'padding-left': '1rem'
        }
    }),
    footerLinkMobileButtons: media(width, height, 'w', {
        1024: {
            display: 'flex',
            'align-items': 'center',
            order: 2
        },
        768: {
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            order: 2
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            order: 1
        }
    }),
    footerMobileStore: media(width, height, 'w', {
        992: {
            padding: '9px 16px',
            'border-radius': '14px',
            'background-color': variable.whiteColor,
            'font-size': '0.625rem',
            display: 'flex',
            'align-items': 'center',
            cursor: 'pointer'
            , width: '100%', 'margin-right': '8px'
        },
        768: {
            padding: '9px 16px',
            'border-radius': '14px',
            'background-color': variable.whiteColor,
            'font-size': '0.625rem',
            display: 'flex',
            'align-items': 'center',
            cursor: 'pointer',
            'margin-bottom': '8px', width: '100%', 'margin-right': '8px'
        },
        0: {
            padding: '9px 16px',
            'border-radius': '14px',
            'background-color': variable.whiteColor,
            'font-size': '0.625rem',
            display: 'flex',
            'align-items': 'center',
            cursor: 'pointer', width: '100%', 'margin-right': '8px'
        }
    }),
    footerMobileStoreLink: media(width, height, 'w', {
        1024: {
            'margin-right': '8px',
            width: '100%',
            color: variable.darkColor
        },
        992: {
            'margin-right': '8px',
            'margin-bottom': '8px',
            width: '100%',
            color: variable.darkColor
        },
        768: {
            width: '100%',
            color: variable.darkColor
        },
        0: {
            width: '100%',
            'margin-right': '8px',
            color: variable.darkColor
        }
    }),
    footerMobileStoreHover: {
        color: variable.blueColor,
        fill: variable.blueColor
    },
    footerInfo: media(width, height, 'w', {
        1700: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '24px 0'
        },
        1250: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '24px 12px'
        },
        768: {
            width: '100%',
            display: 'flex',
            'flex-direction': 'row',
            padding: '12px',
            'flex-wrap': 'wrap',
            'justify-content': 'space-between'
        },
        0: {
            width: '100%',
            display: 'flex',
            'flex-direction': 'column',
            padding: '12px 0'
        }
    }),
    footerInfoContact: media(width, height, 'w', {
        1700: { width: '230px' },
        768: { width: '230px' },
        0: {
            width: '100%',
            'text-align': 'center',
            'padding-bottom': '16px',
            'border-bottom': `1px solid ${variable.darkSuperTransparentColor}`
        }
    }),
    footerMobMenuItemWrap: media(width, height, 'w', {
        768: {},
        0: {
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
            'padding-right': '12px'
        }
    }),
    footerMenuItemWrapper: media(width, height, 'w', {
        1250: {
            'margin-right': '35px',
            'max-width': '239px',
            'min-width': '70px'
        },
        768: {
            'margin-right': '45px',
            'max-width': '200px',
            'min-width': '70px',
            margin: '10px 45px 10px 0px'
        }
    }),
    footerInfoPhone: {
        ...uikit.h5,
        'font-weight': 500,
        color: variable.blueColor,
        'margin-bottom': '11px'
    },
    footerInfoTime: {
        ...uikit.small,
        'margin-bottom': '11px'
    },
    footerInfoSubtitle: {
        ...uikit.tiny,
        color: variable.greyExtraDarkColor
    },
    footerSocialTitle: {
        ...uikit.title,
        'margin-bottom': '16px'
    },
    footerAccordionTitle: {
        ...uikit.title,
        'margin-bottom': '16px'
    },
    footerAccordionTitleMob: media(width, height, 'w', {
        768: {
            'padding': '14px 0 ',
            ...uikit.small,
            'margin-left': '12px'
        },
        0: {
            ...uikit.small,
            'padding': '2px 0',
            'margin-left': '12px'
        }
    }),
    footerAccordionBodyItem: media(width, height, 'w', {
        768: {
            color: variable.blueTotalGray,
            ...uikit.small,
            'margin-bottom': '12px',
            transition: variable.transition,
            cursor: 'pointer'
        },
        0: {
            color: variable.blueTotalGray,
            ...uikit.small,
            'margin-bottom': '12px',
            transition: variable.transition,
            cursor: 'pointer',
            'margin-left': '12px'
        }
    }),
    footerInfoBody: media(width, height, 'w', {
        1700: {
            display: 'flex',
            'flex-wrap': 'wrap'
        },
        768: {
            display: 'flex',
            'flex-wrap': 'wrap'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    footerAccordionBodyItemHover: { color: variable.blueColor },
    singleFooterSocialWrapper: media(width, height, 'w', {
        768: {},
        0: {
            'margin-top': '14px',
            'border-bottom': `1px solid ${variable.darkExtraTransparentColor}`,
            'padding': '0px 12px 16px 12px'
        }
    }),
    footerSocial: {
        display: 'flex',
        'align-items': 'center'
    },
    footerSocialIcon: {
        width: '48px',
        height: '48px',
        'border-radius': '50%',
        'margin-right': '8px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'cursor': 'pointer'
    },
    footerSocialIconHover: { opacity: 0.8 },
    submenuOverlay: media(width, height, 'w', {
        993: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            'z-index': 1
        },
        0: { display: 'none' }
    }),
    officeMenuItemArrowMob: media(width, height, 'w', {
        993: { display: 'none' },
        0: {
            width: '10px',
            height: '10px',
            fill: variable.darkHalfTransparentColor,
            'margin-left': '15px',
            'margin-right': '14px'
        }
    }),
    officeMenuItemArrowMobRotate: { transform: 'rotate(90deg)' },
    agreementLink: media(width, height, 'w', {
        991: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '700',
            'font-size': '14px',
            'line-height': '20px',
            color: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer'
        },
        768: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '700',
            'font-size': '14px',
            'line-height': '20px',
            color: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer',
            'margin-bottom': '8px'
        },
        0: {
            'font-family': 'Gilroy, sans-serif',
            'font-weight': '700',
            'font-size': '12px',
            'line-height': '17px',
            'text-align': 'center',
            color: 'rgba(46, 109, 142, 1)',
            cursor: 'pointer',
            'margin-bottom': '8px'
        }
    }),
    agreementLinkMargin: media(width, height, 'w', {
        991: { 'margin-right': '16px' },
        0: { 'margin-right': '8px' }
    }),
    footerLowerAgreements: media(width, height, 'w', {
        991: {
            'margin-top': '0',
            display: 'flex',
            'align-items': 'center'
        },
        768: {
            'margin-top': '24px',
            'text-align': 'center'
        }
    }),
    agreementLinkHover: { color: 'rgba(164, 179, 202, 1)' }
});
export default stylesheets;