import React, { useContext } from 'react';
import {
    Div, P, Footer
} from '../../../hybrid/wrappers';
import Styles from '../../../common/decorators/Styles';
import getStyles from '../../../project/styles/layouts-styles/single-layout-styles';
import T from '../../../common/components/T';
import { navigate } from '../../../common/utils/router';
import UserContext from '../../../common/utils/getContext';

const SingleUnderFooter = (props) => {
    const { lang } = useContext(UserContext);
    const { styles, customStyles } = props;
    const currentYear = new Date().getFullYear();

    return (
        <Div styles={{ ...styles.footerDown, ...customStyles?.footerDown }}>
            <Footer styles={{
                ...styles.blockXl, ...styles.fullWidth, ...styles.footer
            }}>
                <P styles={styles.copyright}>
                    <T textName='copyright-new123' vars={{ currentYear: currentYear }} defaultStr='© ${currentYear} «eternity.inc»' page='all' />
                </P>
                <Div styles={styles.dev}>
                    <P
                        styles={{ ...styles.agreementLink, ...styles.agreementLinkMargin }}
                        effects={{ hover: styles.agreementLinkHover }}
                        onClick={() => navigate('/[lang]/page/[page]', '/page/agreement', lang)}
                    >
                        <T textName='agreement-label' defaultStr='Пользовательское соглашение' page='all' />
                    </P>
                    <P
                        styles={{ ...styles.agreementLink, ...styles.agreementLinkMargin }}
                        effects={{ hover: styles.agreementLinkHover }}
                        onClick={() => navigate('/[lang]/page/[page]', '/page/privacy_policy', lang)}
                    >
                        <T textName='privacy-label' defaultStr='Политика конфиденциальности' page='all' />
                    </P>
                    <P
                        styles={{ ...styles.agreementLink, 'margin-bottom': 0 }}
                        effects={{ hover: styles.agreementLinkHover }}
                        onClick={() => navigate('/[lang]/page/[page]', '/page/aml', lang)}
                    >
                        <T textName='aml-label' defaultStr='AML' page='all' />
                    </P>
                </Div>
            </Footer>
        </Div>

    );
};

export default Styles(SingleUnderFooter, getStyles);