// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useContext, useMemo
} from 'react';
import {
    Div, P, svgSprite, BlankLink, A
} from '../../../hybrid/wrappers';
import UserContext from '../../../common/utils/getContext';
import { getItemMenu } from '../helpers';
import uikit from '../../../project/uikit/styles';
import PropTypes from 'prop-types';

const SingleMenu = ({
    styles,
    isMobile,
    active,
    hideOnComponent
}) => {
    const { lang, menu, component } = useContext(UserContext);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [showSubMenuIdx, setShowSubMenuIdx] = useState(-1);
    const headerMenu = useMemo(() => getItemMenu(menu?.site, lang), [menu?.site, lang]);
    if (component === hideOnComponent) return null;
    return headerMenu.map((menuItem, idx) => {
        const isActive = menuItem.asLink === active;
        if (!menuItem.activity) return null;
        return (
            <Div
                key={`SingleMenu_${idx}`}
                styles={{ ...styles.wrapperItem, ...isMobile && { ...uikit.flexColumn } }}>
                {/*{showSubMenu && <Div data-uitest='659_uitest' onClick={() => { setShowSubMenu(false) }} styles={styles.submenuOverlay} />}*/}
                <Div
                    {...(menuItem.type === 'part' && menuItem?.submenu?.length ? {
                        onClick: () => {
                            setShowSubMenu(prev => {
                                !prev ? setShowSubMenuIdx(idx) : setShowSubMenuIdx(-1)
                                return !prev
                            })
                        }
                    } : {})}
                    styles={{
                        ...styles.mainMenuItem, ...showSubMenu && idx === showSubMenuIdx ? { 'padding-bottom': '0', ...styles.mainMenuItemHover } : {}, ...!isMobile && idx === headerMenu?.length - 1 ? { 'padding-right': '0' } :{}
                    }}
                    effects={{ hover: styles.mainMenuItemHover }}
                >

                    {!!isMobile && <Div styles={styles.menuItemImg}>
                        {svgSprite(menuItem.icon, {
                            width: '24px',
                            height: '24px',
                            fill: showSubMenu && idx === showSubMenuIdx ? styles.variable.blueColor : 'inherit'
                        })}
                    </Div>}
                    <Div styles={styles.officeMenuItemText}>
                        {
                            menuItem.type === 'outLink' ?
                                <P styles={isActive
                                    ? { ...styles.officeMenuItemTextP, ...styles.officeMenuItemTextHover }
                                    : styles.officeMenuItemTextP}
                                effects={{ hover: styles.officeMenuItemTextHover }}
                                >
                                    <BlankLink
                                        styles={styles.itemMenuStyle}
                                        title={menuItem.title}
                                        href={`https://${menuItem.asLink}`}
                                        target={menuItem?.target ? '_blank' : '_self'}
                                    />
                                </P>

                                :
                                menuItem.type === 'link' || menuItem.type === 'page' ?
                                    <P styles={isActive
                                        ? { ...styles.officeMenuItemTextP, ...styles.officeMenuItemTextActive }
                                        : styles.officeMenuItemTextP}
                                    effects={{ hover: styles.officeMenuItemTextOuterHover }}
                                    >
                                        <A styles={styles.itemMenuStyle}
                                            effects={{ hover: styles.officeMenuItemTextHover }}
                                            title={menuItem.title}
                                            href={`/${lang}${menuItem.type === 'page' ? '/page' : ''}${menuItem.asLink}`}
                                            target={menuItem?.target ? '_blank' : '_self'}
                                        />
                                    </P>
                                    : <P styles={isActive
                                        ? { ...styles.officeMenuItemTextP, ...styles.officeMenuItemTextHover }
                                        : styles.officeMenuItemTextP}
                                    effects={{ hover: styles.officeMenuItemTextHover }}>
                                        { menuItem.title}
                                    </P>
                        }
                        {
                            !!menuItem.submenu.length && !!isMobile ? svgSprite('angle-right', { style: showSubMenu && idx === showSubMenuIdx ? { ...styles.officeMenuItemArrowMob, ...styles.officeMenuItemArrowMobRotate } : styles.officeMenuItemArrowMob }) : null
                        }
                        {
                            !!menuItem.submenu.length && !isMobile ?
                                <Div styles={{
                                    display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'margin-left': '7.25px'
                                }}>
                                    {svgSprite('angle-down', {
                                        style: {
                                            width: '7.5px',
                                            height: '3.75px',
                                            fill: styles?.officeMenuItemTextSvgFill?.fill || styles.variable.darkHalfTransparentColor,
                                            ...showSubMenu && idx === showSubMenuIdx ? { transform: 'rotate(180deg)' } :{ transform: 'rotate(0)' },
                                            transition: 'all .3s ease'
                                        }
                                    })
                                    }</Div>: null
                        }
                    </Div>
                </Div>
                {
                    menuItem?.submenu.length
                        ?
                        <Div styles={{ ...styles.subMenuWrapper, ...showSubMenu && idx === showSubMenuIdx ? { 'padding-bottom': '8px' } : { display: 'none' } }}>
                            {
                                menuItem?.submenu.map((item, idx) =>
                                    <Div key={`submenuItem${idx}`}
                                        styles={active === item.asLink
                                            ? { ...styles.singleSubMenuItem, ...styles.adminSubMenuActive }
                                            : { ...styles.singleSubMenuItem }
                                        }
                                        effects={{ hover: styles.singleSubMenuItemHover }}
                                    >
                                        {/*{!!isMobile && item?.icon ? <Div styles={styles.menuItemImg}>*/}
                                        {/*    {svgSprite(item.icon, {*/}
                                        {/*        width: '18px',*/}
                                        {/*        height: '18px',*/}
                                        {/*        fill: styles.variable.blueTotalGray*/}
                                        {/*    })}*/}
                                        {/*</Div> : null}*/}
                                        <Div styles={styles.officeMenuItemText}>
                                            {
                                                item.type === 'outLink' ?
                                                    <P styles={isActive
                                                        ? { ...styles.officeMenuItemTextP, ...styles.officeMenuItemTextHover }
                                                        : styles.officeMenuItemTextP}
                                                    >
                                                        <BlankLink
                                                            styles={{ color: !isMobile ? styles.variable.darkColor : styles.variable.blueTotalGray }}
                                                            title={item.title}
                                                            href={`https://${item.asLink}`}
                                                            target={item?.target ? '_blank' : '_self'}
                                                            effects={{ hover: { color: `${styles?.variable?.blueColor} !important` } }}
                                                        />
                                                    </P>

                                                    :
                                                    item.type === 'link' || item.type === 'page' ?
                                                        <A
                                                            style={{ color: !isMobile ? styles.variable.darkColor : styles.variable.blueTotalGray }}
                                                            title={item.title}
                                                            href={`/${lang}${item.type === 'page' ? '/page' : ''}${item.asLink}`}
                                                            target={item?.target ? '_blank' : '_self'}
                                                            effects={{ hover: { color: `${styles?.variable?.blueColor} !important` } }}
                                                        />
                                                        : item.title
                                            }

                                        </Div>
                                    </Div>)
                            }

                        </Div>
                        : null
                }
            </Div>
        )
    })
};
SingleMenu.propTypes = {
    isMobile: PropTypes.bool,
    active: PropTypes.string,
    styles: PropTypes.object
};

export default SingleMenu;